// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/opt/build/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-beecon-tsx": () => import("/opt/build/repo/src/pages/beecon.tsx" /* webpackChunkName: "component---src-pages-beecon-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/build/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plainsdedicated-tsx": () => import("/opt/build/repo/src/pages/plainsdedicated.tsx" /* webpackChunkName: "component---src-pages-plainsdedicated-tsx" */)
}

